<template>
  <v-container style="height: 280px; margin: 30px">
    <div class="row mb-3">
      <div class="col-lg-3 col-md-6 col-sm-12">
        <v-img alt="TurismoBC Logo" class="shrink mt-1" contain max-width="200" max-height="200" :src="
                company.logo_url != null
                  ? company.logo_url
                  : require('@/assets/images/no-image.jpg')
              " :lazy-src="require('@/assets/images/no-image.jpg')" width="200"/>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 mt-0">
        <div class="row mt-0">
          <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-start align-start flex-column">
            <v-btn transparent text plain to="/hospitals-clinics">
              <span>Hospitals and Clinics</span>
            </v-btn>
            <v-btn transparent text plain to="/medical-services">
              <!-- #services -->
              <span>Medical Services</span>
            </v-btn>
            <v-btn transparent text plain to="/pharmaceutics">
              <span>Pharmacies</span>
            </v-btn>
            <v-btn transparent text plain to="/transportations">
              <span>Transport</span>
            </v-btn>
            <v-btn transparent text plain to="/hotels">
              <span>Hotels</span>
            </v-btn>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-start align-start flex-column">
            <v-btn transparent text plain to="/other-services">
              <span>Other services</span>
            </v-btn>
            <v-btn transparent text plain to="/about">
              <span>About us</span>
            </v-btn>
            <a title="Baja California Travel" href="https://bajacalifornia.travel" target="_blank"
               rel="noopener noreferrer">
              <v-img alt="Baja California Travel" max-width="200" max-height="200"
                     src='@/assets/images/bclogocolores.png'
                     width="100"/>
            </a>
            <a title="Baja California Travel" href="https://www.bajacalifornia.gob.mx/secture" target="_blank"
               rel="noopener noreferrer">
              <v-img alt="Baja California Travel" max-width="150" max-height="150"
                     src='@/assets/images/secture-Institucional.png' width="150"/>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 d-flex justify-start align-start flex-column">
        <span class="text-body-2 mb-3">{{ company.email }}</span>
        <span class="text-body-2 mb-3">assistance@baja.gob.mx</span>
        <span class="text-body-2 mb-3 text-wrap">{{ company.phone1 | formatPhone("MX") }}, <br/>
                {{ company.phone2 | formatPhone("US") }}
              </span>
        <v-flex>
          <div style="width: 100px; display: flex">
            <div v-if="company.facebook_profile != null">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="blue" dark v-bind="attrs" v-on="on" icon :href="company.facebook_profile"
                         target="_blank">
                    <v-icon size="24px">mdi-facebook</v-icon>
                  </v-btn>
                </template>
                <span>Facebook</span>
              </v-tooltip>
            </div>
            <div v-if="company.instagram_profile != null">
              <v-tooltip top v-if="company.instagram_profile != null">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="#D93F8FFF" dark v-bind="attrs" v-on="on" icon :href="company.instagram_profile"
                         target="_blank">
                    <v-icon size="24px">mdi-instagram</v-icon>
                  </v-btn>
                </template>
                <span>Instagram</span>
              </v-tooltip>
            </div>
          </div>
        </v-flex>
      </div>
    </div>
    <div class="footer-copyrigth">
      <span class="text-body-2 text--darken-1">
      &copy;{{ new Date().getFullYear() }}. All rights reserved. Developed and designed by
        <a target="_blank" href="https://www.idoogroup.com">IdooGroup</a>.
    </span>
    </div>
  </v-container>
</template>

<script>
import companyService from "@/providers/CompanyService";

export default {
  name: "FooterComponent",
  data: () => ({
    company: {
      id: 0,
      name: "",
      address: "",
      email: "",
      web_page: "",
      phone1: "",
      phone2: "",
      facebook_profile: "",
      instagram_profile: "",
      youtube_video: "",
      logo_url: "",
    },
  }),
  filters: {
    formatPhone(phn, country) {
      // console.log('phn:', phn);
      if (phn == "undefined" || phn == null || phn === "") {
        return country;
      }
      switch (country) {
        case "US":
          return (
              "USA +1 (" +
              phn.substring(0, 3) +
              ") " +
              phn.substring(3, 6) +
              "-" +
              phn.substring(6)
          );
        case "MX":
          return (
              "MEX +52 (" +
              phn.substring(0, 3) +
              ") " +
              phn.substring(3, 6) +
              "-" +
              phn.substring(6)
          );
        default:
          return country;
      }
    },
  },
  methods: {
    getCompany() {
      companyService.getViewData().then((record) => {
        // console.log("Record for Company: ", record.value);
        if (record.value !== null) {
          this.company = record.value;
          // console.log("company: ", this.company);
        }
      });
    },
  },
  beforeMount() {
    this.getCompany();
  },
};
</script>

<style scoped>
.backcolor {
  /* background-color: rgba(21, 27, 41, 1); */
  background: linear-gradient(to top, #ffffff, #eeeeee);

  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.v-btn {
  text-transform: none !important;
}

.footer-copyrigth {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.footer-logo {
  object-fit: contain;
  max-width: 150px;
  max-height: 40px;
  height: 50px;
  width: auto;
}

@media (max-width: 767px) {
  .footer-logo {
  }

  .footer-copyrigth {
    width: 100%;
    justify-content: start;
  }
}
</style>
